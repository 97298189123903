import { useStaticQuery, graphql } from "gatsby";

export const EducationStaticQuery = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query EducationStaticQuery {
        allFile(
          filter: { relativeDirectory: { regex: "/education/" } }
          sort: { fields: relativePath, order: ASC }
        ) {
          group(field: relativeDirectory) {
            category: fieldValue
            topics: edges {
              node {
                childMarkdownRemark {
                  frontmatter {
                    blurb
                  }
                  headings(depth: h1) {
                    value
                  }
                  fields {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return allFile.group;
};
