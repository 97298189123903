/*
 * LicenseSure
 * https://licensesure.biz
 *
 * Basic education service.
 */
import * as StringFormatService from "./string-format.service";

/**
 * Returns other topics from the same category, specified by the current topic.
 *
 * @param categories
 * @param currentTopic
 */
export function getAnyMoreTopics(categories, currentTopic) {
  const category = findCorrespondingCategoryByCurrentTopic(
    categories,
    currentTopic
  );

  if (!category) {
    return;
  }

  // Return other topics from the same category of topics, excluding the current topic
  return category.topics.filter((topic) => topic.slug !== currentTopic);
}

/**
 * Returns the current topic's next article within the same category.
 *
 * @param categories
 * @param currentTopic
 * @returns {*}
 */
export function getNextArticle(categories, currentTopic) {
  const category = findCorrespondingCategoryByCurrentTopic(
    categories,
    currentTopic
  );

  if (!category || !category.topics) {
    return;
  }

  const nextArticleIndex =
    category.topics.findIndex((topic) => topic.slug === currentTopic) + 1;

  return category.topics[nextArticleIndex];
}

/**
 * Returns a category from which the current topic is specified.
 *
 * @param categories
 * @param currentTopic
 * @returns {*}
 */
function findCorrespondingCategoryByCurrentTopic(categories, currentTopic) {
  if (!categories) {
    return;
  }

  // Get the relevant category of the current topic
  return categories.find((category) => {
    if (!category.topics) {
      return false;
    }

    return category.topics.some((topic) => topic.slug === currentTopic);
  });
}

/**
 * Flattens education headings and blurb.
 *
 * @param categories
 */
export function flattenEducationTopics(categories) {
  return categories.map((category) => {
    // Convert category (file name) to string
    const categoryName = reformatCategoryToString(category.category);

    // Flatten topics
    const topics = category.topics
      .filter((topic) => !!topic.node.childMarkdownRemark)
      .map((topic) => {
        return {
          blurb: topic.node.childMarkdownRemark.frontmatter.blurb,
          heading: topic.node.childMarkdownRemark.headings[0].value,
          slug: topic.node.childMarkdownRemark.fields.slug,
        };
      });

    // Return flattened topics with category name
    return {
      categoryId: category.category.split("/").slice(-1),
      category: categoryName,
      topics: topics,
    };
  });
}

/**
 * Returns a reformatted category name specified by path name.
 *
 * @param pathName
 * @returns {*}
 */
function reformatCategoryToString(pathName) {
  const name = pathName.split("/")[1];
  const categoryName = name.replace(/-/g, " ");

  return StringFormatService.convertSentenceCaseToTitleCase(categoryName);
}
