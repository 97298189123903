/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - topics component.
 */

// Core dependencies
import { Link } from "gatsby";
import React from "react";

// Styles
import { topic as topicStyles } from "./topics.module.css";

class Topics extends React.Component {
  render() {
    const { topics } = this.props;
    return (
      <ul className={topicStyles}>
        {topics.map(({ blurb, heading, slug }) => (
          <li key={slug}>
            <h3>
              <Link to={slug}>{heading}</Link>
            </h3>
            <Link to={slug}>{blurb ? <p>{blurb}</p> : null}</Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default Topics;
