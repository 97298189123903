/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - category component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Topics from "./topics";

// Styles
import { category as categoryStyles } from "./category.module.css";

class Category extends React.Component {
  render() {
    const { category, categoryId, topics } = this.props;
    return (
      <div className={categoryStyles}>
        <h2 id={categoryId}>{category}</h2>
        <Topics topics={topics} />
      </div>
    );
  }
}

export default Category;
