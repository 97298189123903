/*
 * LicenseSure
 * https://licensesure.biz
 *
 * Utility class for formatting string values.
 */

/**
 * Convert sentence case to title case. Based off https://stackoverflow.com/a/196991.
 */
export function convertSentenceCaseToTitleCase(title) {
  return title.replace(/\w\S*/g, (text) => {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });
}
