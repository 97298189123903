/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - education page.
 */

// Core dependencies
import React from "react";

// App dependencies
import { EducationStaticQuery } from "../hooks/educationQuery";
import * as EducationService from "../utils/education.service";
import Layout from "../components/layout";
import Category from "../components/topic/category";

// Styles
import { container } from "../templates/contentTemplate.module.css";

export default function Education() {
  const categories = EducationService.flattenEducationTopics(
    EducationStaticQuery()
  );
  return (
    <Layout>
      <div className={container}>
        <h1>Education Center</h1>
        {categories.length
          ? categories.map((category, i) => (
              <Category
                key={i}
                categoryId={category.categoryId}
                category={category.category}
                topics={category.topics}
              />
            ))
          : null}
      </div>
    </Layout>
  );
}
